import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { PARAMS } from '@app/config/params';
import { ApiService } from './api.service';
import * as lodash from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  poPlantData: any;
  taxData: any;

  constructor(private apiService: ApiService) {

  }

  public getPlantData() {
    return this.poPlantData;
  }

  public setPlantData(plantData) {
    this.poPlantData = plantData;
  }

  public setTaxDataBasedOnCountry(taxdata){
    this.taxData = taxdata;
  }

  public getTaxDataBasedOnCountry(){
    return this.taxData;
  }

  /**
   * @todo in case empty rehit details api.
   */
  public getBuyerGstn() {
    let gstn = '';
    if (lodash.has(this.poPlantData, 'branch.branchLang.gstn')) {
      gstn = this.poPlantData.branch.branchLang.gstn;
    }
    return gstn;
  }

}
