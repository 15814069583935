import { AuthService } from '@app/services/auth.service';
import { BrandModel } from './brand.model';

export class Rate {
    amount: number;
    currency: string;
    constructor(amount: number, currency: string) {
        this.amount = (amount) ? amount : null;
        this.currency = currency;
    }
}

export class ProductType {
    _id: string;
    displayName: string;
    constructor(_id: string) {
        this._id = _id;
        this.displayName = _id;
    }
}

export class ExtraDetails {
    description: string;
    remarks: string;
    arcRate: Rate;
    incoterms: string;
    constructor(description: string, remarks: string, arcRate: Rate, incoterms: string) {
        this.description = description;
        this.remarks = remarks;
        if (arcRate.amount) {
            this.arcRate = arcRate;
        }
        this.incoterms = incoterms;
    }
}

export class ProductPrice {
    rate: Rate;
    mrp: Rate;
    constructor(rate: Rate, mrp: Rate) {
        this.rate = rate;
        if (mrp.amount) {
            this.mrp = mrp;
        }
    }
}

/**
 * Product Model class is a representation of product data structure. It contains
 * all attributes of product.
 *
 * @author Kuldeep Dangi <kuldeep.dangi@moglix.com>
 */
export class ProductModel {
    _id: string;
    id: string;
    name: string;
    cpn: string;
    hsnCode: string;
    plantId: string;
    companyId: string;
    isActive: boolean
    createdBy: string;
    createdByName: string;
    productType: ProductType;
    productPrice: ProductPrice;
    brand: BrandModel;
    uom: string;
    rate: Rate;
    corporateClient: boolean;
    leadTime: number;
    incoterms: string;
    creationDate: number;
    modificationDate: number;
    remark: string;
    description: string;
    quantityTolerance: string;
    extraDetails: ExtraDetails
    msn: string;

    constructor(_id: string, cpn: string, name: string, plantId: string, companyId: string,
        brand: BrandModel, uom: string, hsnCode: string, rate: number, createdBy: string,
        createdByName: string, mrp?: number, productType?: string, remark?: string,
        description?: string, arcRate?: number, incoTerms?: string, msn?: string, corporateClient?: boolean) {
        this._id = _id;
        this.id = _id;
        this.companyId = companyId;
        this.plantId = plantId;
        this.name = name;
        this.cpn = cpn;
        this.brand = brand;
        this.uom = uom;
        this.hsnCode = hsnCode;
        this.rate = new Rate(rate, 'INR');
        this.productPrice = new ProductPrice(new Rate(rate, 'INR'), new Rate(mrp, 'INR'));
        this.productType = new ProductType(productType);
        this.createdBy = createdBy;
        this.createdByName = createdByName;
        this.isActive = true;
        this.msn = msn;
        this.corporateClient = corporateClient;
        this.extraDetails = new ExtraDetails(this.description, this.remark, new Rate(arcRate, 'INR'), incoTerms);
    }
}
