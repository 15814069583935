import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {

  sideBarOpenStatus:boolean = false;

  constructor() { }

  getSidebarStatus(): boolean{
    return this.sideBarOpenStatus;
  }

  setSidebarStatus(status: boolean){
     this.sideBarOpenStatus = status;
  }
  
}