<div class="dialog-wrapper">
  <div class="align-side">
    <h4>VALIDATIONS</h4>
    <div>
      <mat-icon (click)="closeDialog()">close</mat-icon>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="slno">
      <th mat-header-cell *matHeaderCellDef>S No. </th>
      <td mat-cell *matCellDef="let element; let i = index;" class="td"> {{ element?.itemindex+1 }} </td>
    </ng-container>

    <ng-container matColumnDef="cpn">
      <th mat-header-cell *matHeaderCellDef>CPN </th>
      <td mat-cell *matCellDef="let element; let i = index;"> <span class="td"> {{element?.cpn}} </span> </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element"><span class="error-msg" *ngIf="!element.rfqItemID">
        CPN/Brand incorrect or has expired as per ARC agreement.<br> Either change the PO type from ARC or get the agreement renewed </span><span class="error-msg" *ngIf="element.rfqItemID">
          Invalid RFQ Item ID </span></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index"></tr>
  </table>
</div>