export class SaItemSheetModel {

    plantId: string;
    plantName: string;
    saNo: string;
    materialDesc: string;
    itemDataRemark: string;
    cpn: string;
    brand: string;
    suggestedBrand: string;
    cpt: string;
    totalQty: number;
    consumedQty: number;
    returnedQuantity: number;
    pendingQuantity: number;
    orderFrequency: number;
    uom: string;
    itemId: string;
    spUnit: number;
    hsn: string;
    taxPercent: number;
    expirationDate: number;
    poConsumedRemark: string;
    opsAllocation: string;
    sourcingAllocation: string;
    suggestSupplier: string;
    suggestSupplierName: string;
    suggestSupplierId: string;
    suggestedSupplierPaymentTerms: string;
    sourcingRemark: string;
    creationDate: number;
    msn: string;
    msnDescription: string;
    sourcerEmail: string;


    constructor() {

    }

}
