/**
 * Simple model class for brand where Brand is an attribute of Product.
 *
 * @author Kuldeep Dangi <kuldeep.dangi@moglix.com>
 */
export class BrandModel {

    _id: string;
    id: string;
    displayName: string;

    constructor(_id: string, displayName: string) {
        this._id = _id;
        this.displayName = displayName;
    }
}