import { Injectable } from "@angular/core";
import { ApiService } from "@app/services/api.service";
import { PARAMS } from "@app/config/params";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TeamsService {
  constructor(private apiService: ApiService) {}

  getTeams(data, offset, limit) {
    let url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.TEAMS.SEARCH.URL}?offset=${offset}&limit=${limit}`;
    return this.apiService.post(url, data);
  }

  getCountries() {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.COUNTRY.GET_COUNTRIES.URL}`;
    return this.apiService.get(url);
  }

  getVerticals() {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.VERTICAL.GET_VERTICALS.URL}`;
    return this.apiService.get(url);
  }

  getRegions() {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.REGION.GET_REGIONS.URL}`;
    return this.apiService.get(url);
  }

  createTeam(data) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.TEAMS.CREATE_TEAM.URL}`;
    return this.apiService.post(url, data);
  }
  updateTeam(data) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.TEAMS.UPDATE_TEAM.URL}`;
    return this.apiService.put(url, data);
  }

  getTeam(id) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.TEAMS.GET_TEAM_BY_ID.URL}${id}`;
    return this.apiService.get(url);
  }
}
