import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MESSAGES } from '@app/config/messages';
import { TeamsService } from '@app/modules/account-services/teams/teams.service';
import { AlertMessageService } from '@app/services/alert-message.service';
import { AuthService } from '@app/services/auth.service';
import { PoService } from '@app/services/po.service';
import { distinctUntilChanged, startWith } from 'rxjs';

declare var dataLayer: any;
@Component({
  selector: 'app-pendency-popup',
  templateUrl: './pendency-popup.component.html',
  styleUrls: ['./pendency-popup.component.scss']
})
export class PendencyPopupComponent implements OnInit {

  timeIntervalList: Array<any> = [
    { view: 'Today', value: 'Today' },
    { view: 'Yesterday Till Date', value: 'Yesterday' },
    { view: 'Week Till Date', value: 'WTD' },
    { view: 'Month Till Date', value: 'MTD' },
  ]
  formGroup!: FormGroup;

  regionList: Array<any> = [];
  teamList: Array<any> = this.authService.getEnterpriseTeams();
  kamList: Array<any> = [];
  sourcerList: Array<any> = [];
  csExececutiveList: Array<any> = [];

  regionListOptions: Array<any> = [];
  teamListOptions: Array<any> = [];
  kamListOptions: Array<any> = [];
  sourcerListOptions: Array<any> = [];
  csExececutiveListOptions: Array<any> = [];

  regionMultiFilterCtrl: FormControl = new FormControl();
  teamMultiFilterCtrl: FormControl = new FormControl();
  kamMultiFilterCtrl: FormControl = new FormControl();
  sourcerMultiFilterCtrl: FormControl = new FormControl();
  csExececutiveMultiFilterCtrl: FormControl = new FormControl();

  userId: string = this.authService.getUserId();
  designation: string = this.authService.getBusinessDesignation();
  isManager: boolean = this.authService.getIsManager();
  isSuperTeamAccess: boolean = this.authService.checkIsSuperTeamAccess();
  userEmail: string = this.authService.getUserEmail();

  filterData: any = {};
  productivityData: any = {};
  productivityOutcome: any = {};
  toggleAllRegionsCheckboxState: boolean = false;
  toggleAllTeamsCheckboxState: boolean = false;
  isDisableRefreshBtn: boolean = false;

  constructor(private poService: PoService,
    private authService: AuthService,
    private alertMessage: AlertMessageService,
    private teamService: TeamsService) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.teamMultiFilterCtrlValueChanges();
    if (!this.checkIsManagerOrSuperViser) this.getProductivityDashboardUserData();
    dataLayer.push({ event: "Productivity_Dashboard", userID: this.userId });
  }

  get checkIsManagerOrSuperViser(): boolean {
    return this.authService.getIsManager() || this.authService.checkIsSuperTeamAccess();
  }


  initFormGroup() {
    this.formGroup = new FormGroup({
      timeInterval: new FormControl('Today'),
    })
    if (this.checkIsManagerOrSuperViser) {
      this.addControls();
      this.getRegionList();
      this.getFilterData();
    }
  }

  addControls() {
    this.formGroup.addControl('regionIds', new FormControl([]));
    this.formGroup.addControl('teamIds', new FormControl([]));
    this.formGroup.addControl('kamIds', new FormControl(null));
    this.formGroup.addControl('sourcerIds', new FormControl(null));
    this.formGroup.addControl('csExececutiveIds', new FormControl(null));
  }

  getRegionList() {
    this.teamService.getRegions().subscribe((res: any) => {
      if (res?.status == 200 && res?.success) {
        this.regionList = res?.data.filter((region: any) => region.idVertical == 4 || region.idVertical == 5);
        this.regionMultiFilterCtrlValueChanges();
      } else {
        this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
      }
    }, (err: any) => {
      this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
    })
  }

  getFilterData() {
    let payload = {
      userId: this.userId,
      teamIds: this.formGroup.get('teamIds')?.value?.length ? this.formGroup.get('teamIds')?.value : this.teamList.map((team) => team.id),
      designation: this.designation,
      isManager: this.isManager
    }
    this.poService.getUserDetailsByManager(payload).subscribe((res: any) => {
      if (res?.status == 200 && res?.success) {
        this.filterData = res?.data;
      } else {
        this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
      }
    }, (err: any) => {
      this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
    })
  }

  getDesignation() {
    if (this.checkIsManagerOrSuperViser) {
      if (this.formGroup.get('kamIds')?.value) {
        return this.kamList.filter((list: any) => list.email == this.formGroup.get('kamIds')?.value)[0].designation;
      } else if (this.formGroup.get('sourcerIds')?.value) {
        return this.sourcerList.filter((list: any) => list.email == this.formGroup.get('sourcerIds')?.value)[0].designation;
      } else if (this.formGroup.get('csExececutiveIds')?.value) {
        return this.csExececutiveList.filter((list: any) => list.email == this.formGroup.get('csExececutiveIds')?.value)[0].designation;
      } else {
        return
      }
    } else {
      return this.designation
    }
  }

  getProductivityDashboardUserData() {
    let payload = {
      timeInterval: this.formGroup.get('timeInterval')?.value,
      userEmail: !this.checkIsManagerOrSuperViser ? this.userEmail : this.formGroup.get('kamIds')?.value || this.formGroup.get('sourcerIds')?.value || this.formGroup.get('csExececutiveIds')?.value,
      designation: this.getDesignation()
    }
    this.poService.getProductivityDashboardUserData(payload).subscribe((res: any) => {
      if (res?.code == 200 && res?.success) {
        this.productivityData = res?.data;
      } else if (res?.code == 400 && !res?.success) {
        this.alertMessage.addError(res?.message || MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
      } else {
        this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
      }
    }, (err: any) => {
      this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
    })
  }

  getProductivityOutcome() {
    let payload = {
      userEmail: !this.checkIsManagerOrSuperViser ? this.userEmail : this.formGroup.get('kamIds')?.value || this.formGroup.get('sourcerIds')?.value || this.formGroup.get('csExececutiveIds')?.value,
      designation: this.getDesignation()
    }
    this.poService.getProductivityOutcome(payload).subscribe((res: any) => {
      if (res?.code == 200 && res?.success) {
        this.productivityOutcome = res?.data;
      } else if (res?.code == 400 && !res?.success) {
        this.alertMessage.addError(res?.message || MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
      } else {
        this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
      }
    }, (err: any) => {
      this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
    })
  }

  downloadProductivityReport() {
    let payload: any = {};
    if(this.formGroup.get('kamIds')?.value || this.formGroup.get('sourcerIds')?.value || this.formGroup.get('csExececutiveIds')?.value) {
      payload.timeInterval = this.formGroup.get('timeInterval')?.value;
      payload.userEmails = [this.formGroup.get('kamIds')?.value || this.formGroup.get('sourcerIds')?.value || this.formGroup.get('csExececutiveIds')?.value];
    } else {
      let userEmails: Array<any> = [];
      let teamIds = this.formGroup.get('teamIds')?.value?.length ? this.formGroup.get('teamIds')?.value : this.teamList.map((team) => team.id);
      for (const [designationKey, designation] of Object.entries(this.filterData)) {
        for (const [key, value] of Object.entries(designation)) {
          if(teamIds.includes(Number(key))) {
            let user: any = value;
            userEmails = [...userEmails, ...user];
          }
        }
        if(Object.keys(this.filterData[designationKey]).includes('-1') && this.filterData[designationKey]['-1'].length) userEmails = [...userEmails, ...this.filterData[designationKey]['-1']]; //global User
      }
      payload.timeInterval = this.formGroup.get('timeInterval')?.value;
      payload.userEmails = this.removeDuplicate(userEmails.map((user: any) => user.email));
    }
    this.poService.downloadProductivityReport(payload).subscribe((res: any) => {
      if(res?.code == 200 && res?.success) {
        window.open(res?.data);
      } else {
        this.alertMessage.addError(res?.message || MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
      }
    }, (err: any) => {
      this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
    })
  }

  refreshProductivityDashboardData() {
    if (!this.checkIsManagerOrSuperViser || (this.checkIsManagerOrSuperViser && this.formGroup.get('kamIds')?.value || this.formGroup.get('sourcerIds')?.value || this.formGroup.get('csExececutiveIds')?.value)) {
      this.isDisableRefreshBtn = true;
      let payload = {
        userEmail: !this.checkIsManagerOrSuperViser ? this.userEmail : this.formGroup.get('kamIds')?.value || this.formGroup.get('sourcerIds')?.value || this.formGroup.get('csExececutiveIds')?.value,
      }
      this.poService.refreshProductivityDashboardData(payload).subscribe((res: any) => {
        if (res?.code == 200 && res?.success) {
          this.isDisableRefreshBtn = true;
          this.getProductivityDashboardUserData();
          setTimeout(() => {
            this.isDisableRefreshBtn = false;
          }, 10000)
        } else {
          this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
          this.isDisableRefreshBtn = false;
        }
      }, (err: any) => {
        this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
        this.isDisableRefreshBtn = false;
      })
    }
  }

  changeTimeInterval() {
    if (this.checkIsManagerOrSuperViser) {
      if (this.formGroup?.get('kamIds')?.value || this.formGroup.get('sourcerIds')?.value || this.formGroup.get('csExececutiveIds')?.value) {
        this.getProductivityDashboardUserData();
      }
    } else {
      this.getProductivityDashboardUserData();
    }
  }

  toggleSelectAllRegions(event: any) {
    this.toggleAllRegionsCheckboxState = event;
    this.formGroup.get('regionIds').patchValue(event ? this.regionList.map((region: any) => region?.idRegion) : []);
    this.toggleRegions();
  }

  toggleRegions() {
    this.toggleAllRegionsCheckboxState = JSON.stringify(this.regionList.map((region: any) => region.idRegion)) == JSON.stringify(this.formGroup.get('regionIds')?.value);
    this.teamList = this.formGroup.get('regionIds')?.value?.length ? this.authService.getEnterpriseTeams().filter((team: any) => this.formGroup.get('regionIds')?.value.includes(team.idRegion)) : this.authService.getEnterpriseTeams();
    this.formGroup.get('teamIds').patchValue([]);
    this.toggleTeams();
    this.teamMultiFilterCtrlValueChanges();
  }

  toggleSelectAllTeams(event: any) {
    this.toggleAllTeamsCheckboxState = event;
    this.formGroup.get('teamIds').patchValue(event ? this.teamList.map((team: any) => team?.id) : []);
    this.toggleTeams();
  }

  toggleTeams() {
    this.toggleAllTeamsCheckboxState = JSON.stringify(this.teamList.map((team: any) => team.id)) == JSON.stringify(this.formGroup.get('teamIds')?.value) && this.teamList.length > 0;
    this.formGroup.get('kamIds')?.patchValue(null);
    this.formGroup.get('sourcerIds')?.patchValue(null);
    this.formGroup.get('csExececutiveIds')?.patchValue(null);
    this.updateKamList();
    this.updateSourcerList();
    this.updateCsExececutiveList();
  }

  selectUser(userType: string) {
    switch (userType) {
      case 'kamIds':
        this.formGroup.get('sourcerIds')?.patchValue(null);
        this.formGroup.get('csExececutiveIds')?.patchValue(null);
        break;
      case 'sourcerIds':
        this.formGroup.get('kamIds')?.patchValue(null);
        this.formGroup.get('csExececutiveIds')?.patchValue(null);
        break;
      case 'csExececutiveIds':
        this.formGroup.get('kamIds')?.patchValue(null);
        this.formGroup.get('sourcerIds')?.patchValue(null);
        break;
    }
    this.getProductivityDashboardUserData();
    this.getProductivityOutcome();
  }

  updateKamList() {
    let kamList: Array<any> = [];
    for (const [key, value] of Object.entries(this.filterData['kamMap'])) {
      if (this.formGroup.get('teamIds')?.value.includes(Number(key))) {
        let tempKamList: any = value;
        kamList = [...kamList, ...tempKamList];
      }
    }
    if((this.formGroup.get('regionIds')?.value?.length || this.formGroup.get('teamIds')?.value?.length) && Object.keys(this.filterData['kamMap']).includes('-1') && this.filterData['kamMap']['-1'].length) kamList = [...kamList, ...this.filterData['kamMap']['-1']]; //global Kam
    this.kamList = this.removeDuplicate(kamList);
    this.kamMultiFilterCtrlValueChanges();
  }

  updateSourcerList() {
    let sourcerList: Array<any> = [];
    for (const [key, value] of Object.entries(this.filterData['sourcerMap'])) {
      if (this.formGroup.get('teamIds')?.value.includes(Number(key))) {
        let tempSourcerList: any = value;
        sourcerList = [...sourcerList, ...tempSourcerList];
      }
    }
    if((this.formGroup.get('regionIds')?.value?.length || this.formGroup.get('teamIds')?.value?.length) && Object.keys(this.filterData['sourcerMap']).includes('-1') && this.filterData['sourcerMap']['-1'].length) sourcerList = [...sourcerList, ...this.filterData['sourcerMap']['-1']]; //gloal sourcer
    this.sourcerList = this.removeDuplicate(sourcerList);
    this.sourcerMultiFilterCtrlValueChanges();
  }

  updateCsExececutiveList() {
    let csExececutiveList: Array<any> = [];
    for (const [key, value] of Object.entries(this.filterData['csExecutiveMap'])) {
      if (this.formGroup.get('teamIds')?.value.includes(Number(key))) {
        let tempCsExececutive: any = value;
        csExececutiveList = [...csExececutiveList, ...tempCsExececutive];
      }
    }
    if((this.formGroup.get('regionIds')?.value?.length || this.formGroup.get('teamIds')?.value?.length) && Object.keys(this.filterData['csExecutiveMap']).includes('-1') && this.filterData['csExecutiveMap']['-1'].length) csExececutiveList = [...csExececutiveList, ...this.filterData['csExecutiveMap']['-1']]; //global executive
    this.csExececutiveList = this.removeDuplicate(csExececutiveList);
    this.csExececutiveMultiFilterCtrlValueChanges();
  }

  regionMultiFilterCtrlValueChanges() {
    this.regionMultiFilterCtrl.valueChanges
      .pipe(startWith(''), distinctUntilChanged((prev: any, next: any) => JSON.stringify(prev) === JSON.stringify(next)))
      .subscribe(() => {
        this.regionListOptions = this.sortArrayByName(this.regionListFilter(this.regionMultiFilterCtrl.value));
      });
  }

  regionListFilter(value: string) {
    const filterValue: string = (value != null || value != undefined) ? value.toString().toLowerCase() : "";
    return this.regionList.filter((region: any) => (region.idRegion + region.name).toLowerCase().includes(filterValue));
  }

  teamMultiFilterCtrlValueChanges() {
    this.teamMultiFilterCtrl.valueChanges
      .pipe(startWith(''), distinctUntilChanged((prev: any, next: any) => JSON.stringify(prev) === JSON.stringify(next)))
      .subscribe(() => {
        this.teamListOptions = this.sortArrayByName(this.teamListFilter(this.teamMultiFilterCtrl.value));
      });
  }

  teamListFilter(value: string) {
    const filterValue: string = (value != null || value != undefined) ? value.toString().toLowerCase() : "";
    return this.teamList.filter((team: any) => (team.id + team.name).toLowerCase().includes(filterValue));
  }

  kamMultiFilterCtrlValueChanges() {
    this.kamMultiFilterCtrl.valueChanges
      .pipe(startWith(''), distinctUntilChanged((prev: any, next: any) => JSON.stringify(prev) === JSON.stringify(next)))
      .subscribe(() => {
        this.kamListOptions = this.sortArrayByEmail(this.kamListFilter(this.kamMultiFilterCtrl.value));
      });
  }

  kamListFilter(value: string) {
    const filterValue: string = (value != null || value != undefined) ? value.toString().toLowerCase() : "";
    return this.kamList.filter((kam: any) => (kam.userId + kam.email).toLowerCase().includes(filterValue));
  }

  sourcerMultiFilterCtrlValueChanges() {
    this.sourcerMultiFilterCtrl.valueChanges
      .pipe(startWith(''), distinctUntilChanged((prev: any, next: any) => JSON.stringify(prev) === JSON.stringify(next)))
      .subscribe(() => {
        this.sourcerListOptions = this.sortArrayByEmail(this.sourcerListFilter(this.sourcerMultiFilterCtrl.value));
      });
  }

  sourcerListFilter(value: string) {
    const filterValue: string = (value != null || value != undefined) ? value.toString().toLowerCase() : "";
    return this.sourcerList.filter((sourcer: any) => (sourcer.userId + sourcer.email).toLowerCase().includes(filterValue));
  }

  csExececutiveMultiFilterCtrlValueChanges() {
    this.csExececutiveMultiFilterCtrl.valueChanges
      .pipe(startWith(''), distinctUntilChanged((prev: any, next: any) => JSON.stringify(prev) === JSON.stringify(next)))
      .subscribe(() => {
        this.csExececutiveListOptions = this.sortArrayByEmail(this.csExececutiveListFilter(this.csExececutiveMultiFilterCtrl.value));
      });
  }

  csExececutiveListFilter(value: string) {
    const filterValue: string = (value != null || value != undefined) ? value.toString().toLowerCase() : "";
    return this.csExececutiveList.filter((csExececutive: any) => (csExececutive.userId + csExececutive.email).toLowerCase().includes(filterValue));
  }

  sortArrayByEmail(items: Array<any>) {
    return items.sort((a, b) => a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1)
  }

  sortArrayByName(items: Array<any>) {
    return items.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
  }

  removeDuplicate(arr: Array<any>) {
    let uniqueArr: Array<any> = [];
    for (let i = 0; i < arr.length; i++) {
      let found: boolean = false;
      for (let j = 0; j < uniqueArr.length; j++) {
        if (JSON.stringify(arr[i]) == JSON.stringify(uniqueArr[j])) {
          found = true;
          break;
        }
      }
      if (!found) {
        uniqueArr.push(arr[i])
      }
    }
    return uniqueArr
  }

}