import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class ContentInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let reqHeader: HttpRequest<any> = req;
    /*
    reqHeader = req.clone({
      headers: req.headers
        .append('Access-Control-Allow-Methods', 'GET, POST, DELETE, PUT')
        .append('Access-Control-Allow-Origin', '*')
        .append('Access-Control-Allow-Credentials', 'true')
    });
*/
    return next.handle(reqHeader);
  }
}