import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as lodash from 'lodash';
import { AlertMessageService } from '@app/services/alert-message.service';
import { PARAMS } from '@app/config/params';

@Component({
  selector: 'app-error-validations-dialog',
  templateUrl: './error-validations-dialog.component.html',
  styleUrls: ['./error-validations-dialog.component.scss']
})
export class ErrorValidationsDialogComponent implements OnInit {

  public displayedColumns: string[] = [];
  public dataSource: any;
  public noErrors: boolean = true;
  public displayHeading: string = "";
  public displaySuccessMessage: string = "";

  constructor(private alertMessageService: AlertMessageService, @Inject(MAT_DIALOG_DATA) public validationData: any, public dialogRef: MatDialogRef<ErrorValidationsDialogComponent>) { }

  ngOnInit() {
    this.displayedColumns = ['slno', 'cpn', 'status'];
    this.displayValidation(this.validationData);
  }

  public displayValidation(data) {
    let combinedResponseData = [];
    combinedResponseData = data.errorValidationData;
    this.dataSource = combinedResponseData;
  }

  closeDialog() {
    this.dialogRef.close(this.noErrors);
  }

}
