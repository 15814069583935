import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from '../auth.service';
import { BuyerService } from "../buyer.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private application: string = "EOC";

  constructor(private authService: AuthService,private buyerService: BuyerService) {

  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url) {
      let reqHeader: HttpRequest<any>;
     if (req.url.includes(this.authService.getSalesOpsBaseUrl())) {
      const countryCode = this.buyerService.getPlantData()?.branch?.countryCode;
        reqHeader = req.clone({
          headers: req.headers
            .append(AuthService.SALESOPS_TOKEN, this.authService.getToken() ? `Bearer ${this.authService.getToken()}` : '')
            .append("countryId",String(countryCode))
        });
      }
      else if (req.url.includes(this.authService.getSalesOpsBaseUrl())) {
        const countryCode = this.buyerService.getPlantData()?.branch?.countryCode;
        reqHeader = req.clone({
          headers: req.headers
            .append(AuthService.SALESOPS_TOKEN, this.authService.getToken() ? `Bearer ${this.authService.getToken()}` : '')
            .append("countryId",String(countryCode))
        });
      }
      // checking if APi is for analytics vendor discovery then remove idBranch as they do not accept
      else if(req.url.includes(this.authService.getAnalyticsVendorApiBaseUrl())) {
        reqHeader = req.clone({
        });
      }
      else if (req.url.includes(this.authService.getSalesOpsSupplierBaseUrl())) {
        const token: string = this.authService.getSalesOpsToken();
        reqHeader = req.clone({
          headers: req.headers
            .append(AuthService.SALESOPS_TOKEN, token || '')
        });
      }
      else if (req.url.includes(this.authService.getWmsBaseUrl())) {
        const token: string = this.authService.getWmsToken();
        reqHeader = req.clone({
          headers: req.headers
            .append(AuthService.SALESOPS_TOKEN, token || '')
        });
      }
      else if (req.url.includes(this.authService.getEmsBaseUrl()) || req.url.includes(this.authService.getMceNotificationBaseUrl())|| req.url.includes(this.authService.getPlatformBaseUrl())) {
        reqHeader = req.clone({
        });
      }
      else if (req.url.includes(this.authService.getTsFsBaseUrl())) {
        const token: string = this.authService.getTsFsToken();
        reqHeader = req.clone({
          headers: req.headers
            .append(AuthService.SALESOPS_TOKEN, token || '')
        });
      }
      else if (req.url.includes(this.authService.getSearchSupplierBaseUrl())) {
        const token: string = this.authService.getSearchSupplierToken();
        reqHeader = req.clone({
          headers: req.headers
            .append(AuthService.SEARCH_SUPPLIER_TOKEN, token || '')
        });
      }
      else if (req.url.includes(this.authService.getFinanceBaseUrl())) {
        const token: string = this.authService.getTsFsToken();
        reqHeader = req.clone({
        });
      }
      else {
        let token: string;
        if (req.url.includes(this.authService.getMeCatalogueBaseUrl())) {
          token = this.authService.getMeCatalogueToken();
        } else {
          token = this.authService.getToken() ? `Bearer ${this.authService.getToken()}` : "";
        }
        const userId: string = this.authService.getUserId();
        const companyId: string = this.authService.getCompanyId();
        const applicationId: string = this.authService.getApplicationId();
        const branchId: string = this.authService.getBranchId();
        const emailId: string = this.authService.getUserEmail();
        reqHeader = req.clone({
          headers: req.headers
            .append(AuthService.AUTH_TOKEN, token)
            .append(AuthService.AUTHORIZATION_TOKEN, token)
            .append(AuthService.AUTH_USER_ID, userId || '')
            .append(AuthService.AUTH_BRANCH_ID, branchId || '')
            .append(AuthService.AUTH_COMPANY_ID, companyId || '')
            .append(AuthService.AUTH_APPLICATION_ID, applicationId || '')
            .append(AuthService.APPLICATION, this.application || '')
         });
         if (req.url.includes(this.authService.getAIBaseUrl())) {
          reqHeader = reqHeader.clone({
            headers: reqHeader.headers.append(AuthService.EMAIL_ID, emailId || '')
          });
        }

        if (req.url.includes(this.authService.getFinanceArBaseUrl())) {
          reqHeader = reqHeader.clone({
            headers: reqHeader.headers.append('modulename', 'FIN-API')
          });
        }
      }
      return next.handle(reqHeader);
    }
  }
}

