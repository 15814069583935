export class rfqSheetModel {
    plantName: string;
    enquiryType: string;
    arc: string;
    rfqId: string;
    ageing: string;
    brand: string;
    clarificationDetail: string;
    clarificationNeeded: string;
    cpn: string;
    moglixPO: string;
    cpnDescription: string;
    cpt: string;
    createdBy: string;
    creationDate: number;
    deliveryType: string;
    discount: number;
    finalTp: number;
    gmPercentage: number;
    gstPercent: number;
    hsn: number
    l1Category: string;
    l2Category: string;
    lastSourcedDate: number;
    leadTime: number;
    listPrice: string;
    manualSp:number;
    modificationDate: number;
    moglixDescription: string;
    moglixPOC: string;
    MOQ: string;
    msn: string;
    paymentTerm: string;
    plantId: number;
    pricingCategory: string;
    productName: string;
    purchaser: string;
    purchaserEmail: string;
    qty: number;
    quantity: string;
    quotedDate: string;
    timePostQuote: number;
    reasonForLoss: string;
    remark: string;
    remarks: string;
    reqForGlobalMsn: string;
    rfqIdNew: string;
    rfqStatus: string;
    sp: number;
    tat: string;
    sourcerTAT: string;
    kamTAT: string;
    teamName: string;
    totalValue: string;
    tp: number;
    uomRfq: string;
    vendorName: string;
    rfqItemId: string;
    reasonForRfqLoss: string;
    itemId: string;
    RFQDate: number;
    productDetails: string;
    customerRfqDate: string;
    companyId: number;
    companyName: string;
    vendorQuotedPrice: string;
    gst: number;
    value: string;
    edq: number;
    remark1: string;
    remark2: string;
    remark3: string;
    remark4: string;
    msnDescription: string;
    productType: string;
    customerPoc: string;
    edr: number;
    remarkForPOLostRegret: string;
    poWonByVendor: string;
    tpByCustomer: string;
    moglixVendorRegistered: string;
    priceValidityDate: number;
    customerDiscount: number;
    finalSp: number;
    rfqClosureDate: number;
    teamId: number;
    lpToCustomer: number;
    expectedGM: number;
    highValueRFQ: string;
    clarificationFromSourcing: string;
    clarificationFromCatalog: string;
    msnStatus: string;
    additionalRemark: string;
    warehouseName: string;
    warehouseId: number;
    msnclarificationClosed: string;
    catalogPocMailId: string;
    sourcingLeadTime: number;
    vendorId: string;
    status: string;
    supplierPaymentTerm: string;
    supplierPaymentTermId: number;
    //transferCharges: number;
    gmStarRfq: string;
    gmStar: string;
    gmApprovalStatus: string;
    rejectedFrom: string;
    approvedFrom:string;
    gmApprovalRemark: string;
    scmCost: string;
    cmStar: string;
    cmStarRfq: string;
    arcPrice: number;
    defaulterSupplier: boolean;
    constructor() {

    }
}