<div class="header clearfix">
    <div class="h-left">
        <ng-container *ngIf="isMobile; else notMobile">
            <a class="logo-div">
                <img class="logo" src="assets/img/eoc-logo.svg" alt="eoc logo">
                <div class="project-name">
                    <p>Moglix</p>
                    <h2>EOC</h2>
                </div>
            </a>
        </ng-container>
        <ng-template #notMobile>
            <a class="logo-div" href="">
                <img class="logo" src="assets/img/eoc-logo.svg" alt="eoc logo">
                <div class="project-name">
                    <p>Moglix</p>
                    <h2>EOC</h2>
                </div>
            </a>
        </ng-template>
        <ng-container *ngIf="!isMobile">
            <app-menu-bar></app-menu-bar>
        </ng-container>
    </div>
    <div class="h-right">
        <ng-container>
            <div *ngIf="isProductivityDashboardAccess && authService.getEnterpriseTeams()?.length" class="pendency" (click)="viewProductivityDashboard()">
                <div class="prev-icon">
                    <img src="assets/img/prev-icon.svg" alt="prev icon">
                </div>
                <ng-container *ngIf="checkIsManagerOrSuperViser; else notManagerOrSuperViser">
                    <div class="text-prod">Check Your <br> Team's Productivity</div>
                </ng-container>
                <ng-template #notManagerOrSuperViser>
                    <div class="prodScore"  *ngIf="currentSection === 1">
                        <p class="day-outcome">Today's Outcome</p>
                        <p class="score">{{productivityData?.Today?.calculatedProductivity}}/{{productivityData?.Today?.avgProductivity}}</p>
                        <div class="result-circle red" [ngClass]="{
                            'red': productivityData?.Today?.calculatedProductivity < 80,
                            'yellow': productivityData?.Today?.calculatedProductivity >= 80 && productivityData?.Today?.calculatedProductivity < 100,
                            'green': productivityData?.Today?.calculatedProductivity >= 100}">
                        </div>
                    </div>
                    <div class="prodScore"  *ngIf="currentSection === 2">
                        <p class="day-outcome">YTD's Outcome</p>
                        <p class="score">{{productivityData?.Yesterday?.calculatedProductivity}}/{{productivityData?.Yesterday?.avgProductivity}}</p>
                        <div class="result-circle red" [ngClass]="{
                            'red': productivityData?.Yesterday?.calculatedProductivity < 80,
                            'yellow': productivityData?.Yesterday?.calculatedProductivity >= 80 && productivityData?.Yesterday?.calculatedProductivity < 100,
                            'green': productivityData?.Yesterday?.calculatedProductivity >= 100}">
                        </div>
                    </div>
                    <div class="prodScore"  *ngIf="currentSection === 3">
                        <p class="day-outcome">WTD's Outcome</p>
                        <p class="score">{{productivityData?.WTD?.calculatedProductivity}}/{{productivityData?.WTD?.avgProductivity}}</p>
                        <div class="result-circle" [ngClass]="{
                            'red': productivityData?.WTD?.calculatedProductivity < 80,
                            'yellow': productivityData?.WTD?.calculatedProductivity >= 80 && productivityData?.WTD?.calculatedProductivity < 100,
                            'green': productivityData?.WTD?.calculatedProductivity >= 100}">
                        </div>
                    </div>
                    <div class="prodScore"  *ngIf="currentSection === 4">
                        <p class="day-outcome">MTD's Outcome</p>
                        <p class="score">{{productivityData?.MTD?.calculatedProductivity}}/{{productivityData?.MTD?.avgProductivity}}</p>
                        <div class="result-circle red" [ngClass]="{
                            'red': productivityData?.MTD?.calculatedProductivity < 80,
                            'yellow': productivityData?.MTD?.calculatedProductivity >= 80 && productivityData?.MTD?.calculatedProductivity < 100,
                            'green': productivityData?.MTD?.calculatedProductivity >= 100}">
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="quick-links" *ngIf="!isMobile">
                <img (click)="QuicklinksVisibility()" src="assets/img/quick-links.svg" alt="quick-Links">
                <div *ngIf="quicklink" class="quick-links-popup ">
                    <div class="popup-inner popup-content">
                        <ng-container *ngFor="let link of quickLinks">
                            <a class="quick-link-item" (click)="redirectionWidget(link?.url, link?.view)">
                                <img [src]="link?.imgSrc" alt="No Image Found!">
                                <p>{{link?.view}}</p>
                            </a>
                        </ng-container>
                    </div>
                    <div (click)="QuicklinksVisibility()" class="popup-overlay">
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="profile">
            <button type="button" (click)="profileVisibility()"><img src="assets/user.png"></button>
            <div *ngIf="profile" class="profile-popup">
                <div class="popup-inner popup-content">
                    <div class="name">
                        <h4>{{convertUsenameToTitleCase(username)}}</h4>
                        <p>{{userEmail}}</p>
                        <p>{{designation}}</p>
                    </div>
                    <h3 routerLink="/account/logout">Log Out</h3>
                </div>
                <div (click)="profileVisibility()" class="popup-overlay">
                </div>
            </div>
        </div>
    </div>
</div>
