import { ActivatedRouteSnapshot, DetachedRouteHandle } from "@angular/router";

export class CustomReuseStrategy {
    private routeLeftFrom: string;
    private handlers: { [key: string]: DetachedRouteHandle } = {};
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        if (!route.routeConfig || route.routeConfig.loadChildren) {
            return null;
        }

        return this.handlers[this.getRouteKey(route)];
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if (route.data.reuseRoutesFrom) {
            var reuseRouteFromVerified = route.data.reuseRoutesFrom.indexOf(this.routeLeftFrom) > -1;
            if (reuseRouteFromVerified) {
                return true;
            }
        }
        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        this.routeLeftFrom = route.routeConfig.path;
        return route.data.shouldReuseRoute || false;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
        if (handler) {
            this.handlers[this.getRouteKey(route)] = handler;
        }
    }
    
    private getRouteKey(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot.filter(u => u.url).map(u => u.url).join('/');
     }

}