<div class="productivity-container">
    <button class="btnClose" mat-dialog-close mat-flat-btton type="button">
        <i class="material-icons">
            cancel
        </i>
    </button>
    <div class="top-heading">
        <h3>Productivity&nbsp;Outcome</h3>
        <div class="right-btns">
            <button *ngIf="checkIsManagerOrSuperViser" class="download" mat-flat-button type="button"
                (click)="downloadProductivityReport()">
                <img src="assets/img/more-actions/download.svg" alt="download">
                <span class="mhide">Download</span>
            </button>
            <button class="refresh" mat-flat-button type="button" (click)="refreshProductivityDashboardData()" [disabled]="isDisableRefreshBtn">
                <i class="material-icons" title="Logout">autorenew </i>
                <span class="mhide">Refresh</span>
            </button>
        </div>
    </div>
    <div class="formula">Productivity Score = Actual Tasks Done * (100 / Avg. Benchmark Per Day)</div>
    <form [formGroup]="formGroup" class="outcome-select clearfix">
        <mat-form-field class="po-select-field-small" floatLabel="never">
            <mat-label>Time</mat-label>
            <mat-select matInput formControlName="timeInterval" (selectionChange)="changeTimeInterval()">
                <ng-container *ngFor="let time of timeIntervalList">
                    <mat-option [value]="time?.value">{{time?.view}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <ng-container *ngIf="checkIsManagerOrSuperViser">
            <mat-form-field class="po-select-field-small" floatLabel="never">
                <mat-label>Region</mat-label>
                <mat-select formControlName="regionIds" (selectionChange)="toggleRegions()" matInput multiple>
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'no matching teams found'"
                            [toggleAllCheckboxChecked]="toggleAllRegionsCheckboxState"
                            [showToggleAllCheckbox]="true"
                            [formControl]="regionMultiFilterCtrl"
                            (toggleAll)="toggleSelectAllRegions($event)">
                        </ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let region of regionListOptions">
                        <mat-option [value]="region?.idRegion">
                            {{region?.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="po-select-field-small" floatLabel="never">
                <mat-label>Team Name</mat-label>
                <mat-select formControlName="teamIds" (selectionChange)="toggleTeams()" matInput multiple>
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'no matching teams found'"
                            [toggleAllCheckboxChecked]="toggleAllTeamsCheckboxState"
                            [showToggleAllCheckbox]="true"
                            [formControl]="teamMultiFilterCtrl"
                            (toggleAll)="toggleSelectAllTeams($event)">
                        </ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let team of teamListOptions">
                        <mat-option [value]="team?.id">
                            {{team?.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="po-select-field-small" floatLabel="never">
                <mat-label>KAM</mat-label>
                <mat-select formControlName="kamIds" matInput (selectionChange)="selectUser('kamIds')">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'Search...'"
                            [noEntriesFoundLabel]="'no matching kAMs found'" [formControl]="kamMultiFilterCtrl">
                        </ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let kam of kamListOptions">
                        <mat-option [value]="kam?.email">
                            {{kam?.email}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="po-select-field-small" floatLabel="never">
                <mat-label>Sourcer POC</mat-label>
                <mat-select formControlName="sourcerIds" matInput (selectionChange)="selectUser('sourcerIds')">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'Search...'"
                            [noEntriesFoundLabel]="'no matching sourcer found'" [formControl]="sourcerMultiFilterCtrl">
                        </ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let sourcer of sourcerListOptions">
                        <mat-option [value]="sourcer?.email">
                            {{sourcer?.email}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="po-select-field-small" floatLabel="never">
                <mat-label>CS Executive</mat-label>
                <mat-select formControlName="csExececutiveIds" matInput
                    (selectionChange)="selectUser('csExececutiveIds')">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'Search...'"
                            [noEntriesFoundLabel]="'no matching CS Executive found'"
                            [formControl]="csExececutiveMultiFilterCtrl">
                        </ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let executive of csExececutiveListOptions">
                        <mat-option [value]="executive?.email">
                            {{executive?.email}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </form>
    <div class="productivity-tbl">
        <ng-container *ngIf="checkIsManagerOrSuperViser">
            <div class="pendency">
                <!-- <div class="prev-icon">
                    <img src="assets/img/prev-icon.svg" alt="prev icon">
                </div> -->
                <div class="prodScore">
                    <ng-container *ngIf="formGroup.get('kamIds')?.value || formGroup.get('sourcerIds')?.value || formGroup.get('csExececutiveIds')?.value; else showText">
                        <p class="day-outcome">{{formGroup.get('timeInterval')?.value == 'Yesterday' ? 'YTD' : formGroup.get('timeInterval')?.value}}'s Outcome</p>
                        <p class="score">{{productivityOutcome[formGroup.get('timeInterval')?.value]?.calculatedProductivity}} / {{productivityOutcome[formGroup.get('timeInterval')?.value]?.avgProductivity}}</p>
                        <div class="result-circle" [ngClass]="{
                            'red': productivityOutcome[formGroup.get('timeInterval')?.value]?.calculatedProductivity < 80,
                            'yellow': productivityOutcome[formGroup.get('timeInterval')?.value]?.calculatedProductivity >= 80 && productivityOutcome[formGroup.get('timeInterval')?.value]?.calculatedProductivity < 100,
                            'green': productivityOutcome[formGroup.get('timeInterval')?.value]?.calculatedProductivity >= 100}">
                        </div>
                    </ng-container>
                    <ng-template #showText>
                        <p class="day-outcome">Check Your Team's Productivity</p>
                    </ng-template>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!checkIsManagerOrSuperViser || (checkIsManagerOrSuperViser && formGroup.get('kamIds')?.value || formGroup.get('sourcerIds')?.value || formGroup.get('csExececutiveIds')?.value); else showEmptyTableText">
            <div class="grid-container">
                <div class="grid-item header-blank">Tasks</div>
                <div class="grid-item header"> Actual Tasks <br> Done </div>
                <div class="grid-item header">Avg.&nbsp;Benchmark <br> (Wherever Applicable)</div>

                <div class="grid-item ">Enquiry Item Manual Punching</div>
                <div class="grid-item">{{productivityData?.manualRfqItemPunchCount}}</div>
                <div class="grid-item">{{productivityData?.pocsAvgResponse?.avgManualRfqItemPunchCount}}</div>

                <!-- <div class="grid-item ">Enquiry Item Manual Punching</div>
                <div class="grid-item">{{productivityData?.manualRfqItemPunchCount}}</div>
                <div class="grid-item">{{productivityData?.pocsAvgResponse?.avgManualRfqItemPunchCount}}</div> -->

                <div class="grid-item ">Enquiry Stage MSN Mapping</div>
                <div class="grid-item">{{productivityData?.msnMappedAtEnquiryCount}}</div>
                <div class="grid-item">{{productivityData?.pocsAvgResponse?.avgMsnMappedAtEnquiryCount}}</div>

                <div class="grid-item ">Enquiry Item Sent to Supplier for Quotation (eRFQ)</div>
                <div class="grid-item">{{productivityData?.quoteSentToSupplierCount}}</div>
                <div class="grid-item">{{productivityData?.pocsAvgResponse?.avgQuoteSentToSupplierCount}}</div>

                <div class="grid-item ">Enquiry Item Received Quotation from Supplier (eRFQ)</div>
                <div class="grid-item">{{productivityData?.quoteReceivedFromSupplierCount}}</div>
                <div class="grid-item">{{productivityData?.pocsAvgResponse?.avgQuoteReceivedFromSupplierCount}}</div>

                <div class="grid-item ">Enquiry Item Quoted to Customer</div>
                <div class="grid-item">{{productivityData?.rfqItemQuoteToCustomerCount}}</div>
                <div class="grid-item">{{productivityData?.pocsAvgResponse?.avgRfqItemQuoteToCustomerCount}}</div>

                <div class="grid-item ">CPO Item Punching</div>
                <div class="grid-item">{{productivityData?.cpoItemPunchCount}}</div>
                <div class="grid-item">{{productivityData?.pocsAvgResponse?.avgCpoItemPunchCount}}</div>

                <div class="grid-item ">SPO Item Punched</div>
                <div class="grid-item">{{productivityData?.spoItemPunchedCount}}</div>
                <div class="grid-item">{{productivityData?.pocsAvgResponse?.avgSpoItemPunchedCount}}</div>

                <div class="grid-item ">Internal SPO Line item Punching</div>
                <div class="grid-item">{{productivityData?.internalSPOItemPunchedCount}}</div>
                <div class="grid-item">{{productivityData?.pocsAvgResponse?.avgInternalSPOItemPunchedCount}}</div>

                <div class="grid-item ">Supplier Dropship Invoice Uploading</div>
                <div class="grid-item">{{productivityData?.dropShipInvoiceCount}}</div>
                <div class="grid-item">{{productivityData?.pocsAvgResponse?.avgDropShipInvoiceCount}}</div>

                <!-- <div class="grid-item ">Supplier Assignment for Internal POs</div>
                <div class="grid-item">{{productivityData?.supplierAssignmentForInternalPosCount}}</div>
                <div class="grid-item">{{productivityData?.pocsAvgResponse?.avgSupplierAssignmentForInternalPosCount}}</div> -->

            </div>
        </ng-container>
        <ng-template #showEmptyTableText>
            <div class="empty-table-text">Select team and email id from KAM, Sourcer, CS Executive Dropdown to view productivity of 1 person at a time!</div>
        </ng-template>
    </div>
</div>