import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppProgressBarService {

  private selector: string = 'loader';
  private apploaderSelector: string = 'apploader';
  private element: HTMLElement;
  private appElement: HTMLElement;

  constructor() {
    this.element = document.getElementById(this.selector);
    this.appElement = document.getElementById(this.apploaderSelector);
  }

  public appLoaderStart(): void {
    this.appElement.style['display'] = 'block';
  }

  public appLoaderStop(delay: number = 0): void {
    this.appElement.style['display'] = 'none';
  }

  public start(): void {
    this.element.style['display'] = 'block';
  }

  public stop(delay: number = 0): void {
    this.element.style['display'] = 'none';
  }
}
