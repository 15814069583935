import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { PendencyPopupComponent } from './pendency-popup/pendency-popup.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PoService } from '@app/services/po.service';
import { AlertMessageService } from '@app/services/alert-message.service';
import { MESSAGES } from '@app/config/messages';

declare var dataLayer: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  username: string;
  userEmail: string;
  userId: string;
  opened: boolean = false;
  quicklink: boolean = false;
  profile: boolean = false;
  quickLinks: Array<any> = [
    { view: 'Create Plant', imgSrc: "assets/img/create-plant.svg", url: "https://eoc.moglix.com/account-services/plants/create-plant" },
    { view: 'Punch SPO', imgSrc: "assets/img/punch-order.svg", url: "https://scm.moglix.com/sourcing" },
    { view: 'Check Inventory', imgSrc: "assets/img/raise-spo.svg", url: "https://scm.moglix.com/warehousemanagement/inventoryview" },
    { view: 'Map Supplier Invoice', imgSrc: "assets/img/supplier-invoice.svg", url: "https://scm.moglix.com/warehousemanagement/mapsupplierinvoice" },
    { view: 'Create Customer Invoice', imgSrc: "assets/img/customer-invoice.svg", url: "https://ems.moglix.com/" },
    { view: 'Search MSN/Supplier', imgSrc: "assets/img/eway-bill.svg", url: "https://cis.moglix.com/category-discovery" },
    { view: 'Upload POD', imgSrc: "assets/img/raise-pod.svg", url: "https://ems.moglix.com/enterprise-order/packed" },
    { view: 'Check Supplier Details', imgSrc: "assets/img/map-payments.svg", url: "https://supplieradmin.moglix.com/admin/suppliers" },
    { view: 'Request Access', imgSrc: "assets/img/request-access.svg", url: "https://moglilabs.freshservice.com/support/catalog/items" },
  ]
  isMobile: boolean = false;
  productivityData: any = {};
  designation: string = this.authService.getBusinessDesignation();
  isProductivityDashboardAccess: boolean = false;
  currentSection = 1;

  constructor(public authService: AuthService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private poService: PoService,
    private alertMessage: AlertMessageService) { }
  ngOnInit() {
    let isAuthenticated: boolean = this.authService.isAuthenticated()
    if (isAuthenticated) {
      this.username = this.authService.getUserName();
      this.userEmail = this.authService.getUserEmail();
      this.userId = this.authService.getUserId();
      this.isProductivityDashboardAccess = this.authService.userRoleList.includes('PRODUCTIVITY  WIDGET VIEW');
    }
    document.addEventListener("click", e => {
      var listId = document.getElementById("profileMenu");
      if (listId && (<HTMLElement>e.target).parentElement.parentElement.id != 'profile') {
        this.opened = false;
      }
    });

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    if (!this.checkIsManagerOrSuperViser) this.getProductivityOutcome();

    setInterval(() => {
      this.rotateSections();
    }, 5000);

    setInterval(() => {
      if (!this.checkIsManagerOrSuperViser) this.getProductivityOutcome();
    }, 1800000)
  }

  get checkIsManagerOrSuperViser(): boolean {
    return this.authService.getIsManager() || this.authService.checkIsSuperTeamAccess();
  }

  rotateSections() {
    this.currentSection = (this.currentSection % 4) + 1;
  }

  getProductivityOutcome() {
    if (this.isProductivityDashboardAccess) {
      let payload = {
        userEmail: this.userEmail,
        designation: this.designation
      }
      this.poService.getProductivityOutcome(payload).subscribe((res: any) => {
        if (res?.code == 200 && res?.success) {
          this.productivityData = res?.data;
        } else if (res?.code == 400 && !res?.success) {
          this.alertMessage.addError(res?.message || MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
        } else {
          this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
        }
      }, (err: any) => {
        this.alertMessage.addError(MESSAGES.ERROR.SOMETHING_WENT_WRONG).show();
      })
    }
  }

  convertUsenameToTitleCase(username) {
    username = username.toLowerCase().split(' ');
    for (var i = 0; i < username.length; i++) {
      username[i] = username[i].charAt(0).toUpperCase() + username[i].slice(1);
    }
    return username.join(' ');
  }

  QuicklinksVisibility() {
    this.quicklink = !this.quicklink;
  }

  profileVisibility() {
    this.profile = !this.profile;
  }

  openList() {
    this.opened = !this.opened;
  }

  viewProductivityDashboard() {
    const dialogRef = this.dialog.open(PendencyPopupComponent, {
      width: '30%',
      maxWidth: 'none',
      height: '100%',
      panelClass: 'pendency-popup',
    })
  }

  redirectionWidget(url: string, view: string) {
    dataLayer.push({ event: "Redirection_Widget", action: view, userID: this.userId })
    window.open(url, '_blank');
  }
}
