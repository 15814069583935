import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { PARAMS } from '../config/params';
import { AppProgressBarService } from '@app/services/app-progress-bar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService,
    private AppProgressBarService: AppProgressBarService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    let isAuthenticated: Observable<boolean> | boolean = this.authService.isAuthenticated();

    if (!isAuthenticated) {
      const token = this.authService.getToken();
      if (token) {
        return this.revalidateAuthentication()
      } else {
        this.router.navigate([PARAMS.BUYER_EXCEL_CLOUD.URLS.LOGIN]);
      }
    }
    return isAuthenticated;
  }

  revalidateAuthentication(): Observable<boolean> {
    return this.authService.buildSessionV1();
  }

}
