import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
  const link = document.createElement('link');
  link.rel = 'preconnect';
  link.href = environment.procurement.baseUrl;
  document.head.appendChild(link);

  const link1 = document.createElement('link');
  link1.rel = 'dns-prefetch';
  link1.href = environment.procurement.baseUrl;
  document.head.appendChild(link1);

  const link2 = document.createElement('link');
  link2.rel = 'preconnect';
  link2.href = environment.userManagement.baseUrl;
  document.head.appendChild(link2);

  const link3 = document.createElement('link');
  link3.rel = 'dns-prefetch';
  link3.href = environment.userManagement.baseUrl;;
  document.head.appendChild(link3);

  const link4 = document.createElement('link');
  link4.rel = 'preconnect';
  link4.href = environment.buyerInternal.baseUrl;
  document.head.appendChild(link4);

  const link5 = document.createElement('link');
  link5.rel = 'dns-prefetch';
  link5.href = environment.buyerInternal.baseUrl;
  document.head.appendChild(link5);

  const link6 = document.createElement('link');
  link6.rel = 'preconnect';
  link6.href = environment.ems.baseUrl;
  document.head.appendChild(link6);

  const link7 = document.createElement('link');
  link7.rel = 'dns-prefetch';
  link7.href = environment.ems.baseUrl;
  document.head.appendChild(link7);

  const link8 = document.createElement('link');
  link8.rel = 'preconnect';
  link8.href = environment.salesOps.baseUrl;
  document.head.appendChild(link8);

  const link9 = document.createElement('link');
  link9.rel = 'dns-prefetch';
  link9.href = environment.salesOps.baseUrl;
  document.head.appendChild(link9);

  const link10 = document.createElement('link');
  link10.rel = 'preconnect';
  link10.href = environment.suppliers.baseUrl;
  document.head.appendChild(link10);

  const link11 = document.createElement('link');
  link11.rel = 'dns-prefetch';
  link11.href = environment.suppliers.baseUrl;
  document.head.appendChild(link11);
}
else {
  const link = document.createElement('link');
  link.rel = 'preconnect';
  link.href = environment.procurement.baseUrl;
  document.head.appendChild(link);

  const link1 = document.createElement('link');
  link1.rel = 'dns-prefetch';
  link1.href = environment.procurement.baseUrl;
  document.head.appendChild(link1);

  const link2 = document.createElement('link');
  link2.rel = 'preconnect';
  link2.href = environment.userManagement.baseUrl;
  document.head.appendChild(link2);

  const link3 = document.createElement('link');
  link3.rel = 'dns-prefetch';
  link3.href = environment.userManagement.baseUrl;;
  document.head.appendChild(link3);

  const link4 = document.createElement('link');
  link4.rel = 'preconnect';
  link4.href = environment.buyerInternal.baseUrl;
  document.head.appendChild(link4);

  const link5 = document.createElement('link');
  link5.rel = 'dns-prefetch';
  link5.href = environment.buyerInternal.baseUrl;
  document.head.appendChild(link5);

  const link6 = document.createElement('link');
  link6.rel = 'preconnect';
  link6.href = environment.ems.baseUrl;
  document.head.appendChild(link6);

  const link7 = document.createElement('link');
  link7.rel = 'dns-prefetch';
  link7.href = environment.ems.baseUrl;
  document.head.appendChild(link7);

  const link8 = document.createElement('link');
  link8.rel = 'preconnect';
  link8.href = environment.salesOps.baseUrl;
  document.head.appendChild(link8);

  const link9 = document.createElement('link');
  link9.rel = 'dns-prefetch';
  link9.href = environment.salesOps.baseUrl;
  document.head.appendChild(link9);

  const link10 = document.createElement('link');
  link10.rel = 'preconnect';
  link10.href = environment.suppliers.baseUrl;
  document.head.appendChild(link10);

  const link11 = document.createElement('link');
  link11.rel = 'dns-prefetch';
  link11.href = environment.suppliers.baseUrl;
  document.head.appendChild(link11);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
