import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { MatStepperModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatRippleModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@app/modules/shared/shared.module';
import { AppMaterialModule } from './material-module'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContentInterceptor } from './services/interceptors/content.interceptor';
import { AuthInterceptor } from './services/interceptors/auth.interceptor';
import { AppProgressBarService } from './services/app-progress-bar.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ToastrModule } from 'ngx-toastr';
import { RfqItemHelperService } from './services/helpers/rfq-item-helper.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './modules/pre-po/custom-reuse-startegy';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    Ng2SearchPipeModule,
    FormsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    SharedModule,
    AppMaterialModule,
    AppRoutingModule,
    NgMultiSelectDropDownModule,
    MatTooltipModule
  ],
  providers: [
    AppProgressBarService,
    RfqItemHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy}

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
