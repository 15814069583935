import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AlertMessageService } from '@app/services/alert-message.service';
import { MESSAGES } from '@app/config/messages';
import { PARAMS } from '@app/config/params';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private alertMessageService: AlertMessageService) { }

  public checkEmptyOrNull(value) {
    if (value === null || value === undefined || value === '' || Number.isNaN(value)) {
      return true;
    }
    return false;
  }

  /**
   * function take input as a date/timestamp and convert it into
   * specific format. it returns empty string if provided input is not
   * a valid date.
   *
   * @author Kuldeep Dangi <kuldeep.dangi@moglix.com>
   */
  public formatDate(dateValue) {
    let result = '';
    if (!this.checkEmptyOrNull(dateValue) && moment(dateValue).isValid()) {
      result = moment(dateValue).format('MM/DD/YYYY');
    }
    else if (dateValue === 'NA') {
      result = 'NA';
    }
    return result;
  }

  /**
   * function take input as a date/timestamp and convert it into
   * specific format(date and time 24 hrs). it returns empty string if provided input is not
   * a valid date.
   *
   * @author Burela Raju <b.raju@moglix.com>
   */
  public formatDateandTime(dateValue) {
    let result = '';
    if (!this.checkEmptyOrNull(dateValue) && moment(dateValue).isValid()) {
      result = moment(dateValue).format('MM/DD/YYYY HH:mm');
    }
    else if (dateValue === 'NA') {
      result = 'NA';
    }
    return result;
  }

  public formatDateForSaLineItems(dateValue) {
    let result = null;
    if (!this.checkEmptyOrNull(dateValue) && moment(dateValue).isValid()) {
      result = moment(dateValue).format('MM/DD/YYYY');
    }
    return result;
  }

  public formatDateAndTime(dateValue) {
    let result = '';
    if (!this.checkEmptyOrNull(dateValue) && moment(dateValue).isValid()) {
      result = moment(dateValue).format('MM/DD/YYYY hh:mm a');
    }
    return result;
  }

  public emailFormatValidationCheck(email: string, field?) {
    let result = false;
    if (!this.checkEmptyOrNull(email)) {
      email = email.toString();
      let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(mailformat)) {
        result = true;
      }
      else {
        let error = field ? "Please Enter valid Email Id At :  " + field : "Please Enter valid Email Id."
        this.alertMessageService.addError(error).show();
      }
    }
    return result;
  }
  public purchaserEmailFormatValidationCheck(email: string, field?) {
    let result = false;
    if (!this.checkEmptyOrNull(email)) {
      email = email.toString();
      let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(mailformat)) {
        result = true;
      }
      else {
        let error = field ? "Please Enter valid Email Id At :  " + field : "Please Enter valid Purchaser Email Id."
        this.alertMessageService.addError(error).show();
      }
    }
    return result;
  }

  public moglixEmailFormatValidationCheck(email: string, type: string) {
    let result = false;
    if (!this.checkEmptyOrNull(email)) {
      email = email.toString();
      let mailformat = new RegExp(PARAMS.STATIC_PARAMS.MOGLIX_EMAIL_REGEX, "i");
      if (mailformat.test(email)) {
        result = true;
      }
      else {
        if (type == 'customerPoc') {
          this.alertMessageService.addError(MESSAGES.ERROR.RFQ.VALID_MOGLIX_EMAIL).show();
        } else if (type == 'catalogPocMailId') {
          this.alertMessageService.addError(MESSAGES.ERROR.RFQ.VALID_CATALOG_MOGLIX_EMAIL).show();
        }
      }
    }
    return result;
  }

  /**
   * Function Checks Date Format For RFQ Creation
   *
   * @author Burela Raju
   */
  public dateFormat(dateValue) {
    let days = moment().diff(dateValue, 'days');
    let result: boolean = true;
    if (this.checkEmptyOrNull(dateValue) || !moment(dateValue, "MM/DD/YYYY").isValid()) {
      result = false;
    }
    else if (moment(dateValue, "MM/DD/YYYY") > moment(new Date(), "MM/DD/YYYY")) {
      result = false;
    }
    else if (days > 365) {
      result = false;
    }
    return result;
  }
  /**
   * Function Checks Date Is Greatet Than Todays date or not
   * @author Suchita angularminds@moglix
   */
   public checkDateGreaterThanTodaysDateOrNot(dateValue) {
    let days = moment().diff(dateValue, 'days');
    let result: boolean = false;
    if (moment(dateValue, "MM/DD/YYYY") > moment(new Date(), "MM/DD/YYYY")) {
      result = true;
    }
  
    return result;
  }

  /**
   * Function Checks Date1 Is Greatet Than Date1 date or not
   * @author Suchita angularminds@moglix
   */
   public compareDates(date1,date2) {
    console.log("date1,date2",date1,date2)
    let result: boolean = false;
    if (moment(date1, "MM/DD/YYYY") > moment(date2, "MM/DD/YYYY")) {
      result = true;
    }
    console.log("result date",result)
    return result;
  }
  /**
  * Function returns the value check true/false for negative value
  *
  * @param value
  *
  * @author Burela Raju
  */

  public checkNegativeValue(value) {
    let validDate: boolean = true;
    if (Math.sign(value) == -1) {
      validDate = false;
    }
    return validDate;
  }

  checkDateFormat(value) {
    let result: boolean = true;
    if (!moment(value, "MM/DD/YYYY",true).isValid() || Math.sign(moment(value, 'MM/DD/YYYY', true).valueOf()) == -1) {
      result = false;
    }
    return result;
  }

  removeSpecialCharsInString(data) {
    data = data.replace(/[^ a-zA-Z0-9&]/g, '');
    return data.trim();
  }

  removeSpecialCharsInStringWithSpaces(data) {
    data = data.replace(/[^a-zA-Z0-9]/g, '');
    return data.trim();
  }

  removeSpecialCharsExceptCommon(data) {
    data = data.replace(/[^ /&,()a-zA-Z0-9]/g, '');
    return data.trim();
  }

  /**
* Function returns the object with isValid and type field for customer due delivery date value
*
* @author Suchita Dabhade
*/

  public customerDueDateCheckWithErrorType(deliveryDate) {
    let result = { isValid: false, type: '' };
    if (!this.checkEmptyOrNull(deliveryDate)) {
      if (!moment(deliveryDate).isValid()) {
        result = { isValid: false, type: 'invalidDateError' };
      } else if (moment(deliveryDate).isValid() && moment(deliveryDate).isBefore(new Date().setHours(0, 0, 0, 0).valueOf())) {
        result = { isValid: false, type: 'minDateError' }
      } else if (moment(deliveryDate).isValid() && moment(deliveryDate).isAfter(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).setHours(0, 0, 0, 0).valueOf())) {

        result = { isValid: false, type: 'maxDateError' };
      } else {
        result = { isValid: true, type: '' };
      }
    }
    return result
  }

  /**
* Function returns the value check true/false for customer due delivery date value
*
* @author Suchita Dabhade
* 
*/
  public customerDueDeliveryDateCheck(deliveryDate) {
    let result = false;
    if (!this.checkEmptyOrNull(deliveryDate)) {
      result = moment(deliveryDate).isValid() && moment(deliveryDate).isSameOrAfter(new Date().setHours(0, 0, 0, 0).valueOf()) && moment(deliveryDate).isSameOrBefore(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).setHours(0, 0, 0, 0).valueOf());
    }
    return result
  }


  public validateCommaSeparatedEmailIds(CommaSeparatedEmailIdsString) {
    let valid = true
    var emails = CommaSeparatedEmailIdsString.replace(/,\s*$/, "").split(",");
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    for (const email of emails) {
      if (email == "" || !regex.test(String(email).trim().toLowerCase())) {
        valid = false;
      }
    }
    return valid;
  }

  public validatePhoneNumber(phoneNumber) {
    let result = false;
    let phoneNumberRegex = PARAMS.STATIC_PARAMS.PHONE_NUMBER_REGEX_10DIGIT
    if (!this.checkEmptyOrNull(phoneNumber) && phoneNumberRegex.test(phoneNumber)) {
      result = true;
    }
    return result;
  }
  
  public validateConsigneeNumber(phoneNumber) {
    let result = false;
    let phoneNumberRegex = PARAMS.STATIC_PARAMS.CONSIGNEE_CONTACT_REGEX
    if (!this.checkEmptyOrNull(phoneNumber) && phoneNumberRegex.test(phoneNumber)) {
      result = true;
    }
    return result;
  }
  getStringFormat(value) {
    return !this.checkEmptyOrNull(value) ? value.toString() : "";
  }

  getNumberFormat(value) {
    return !this.checkEmptyOrNull(value) ? parseFloat(value) : null;
  }

  getDateFormatToLongInt(value) {
    return !this.checkEmptyOrNull(value) ? moment(value, 'MM/DD/YYYY', true).valueOf() : null;
  }
  getBooleanFormat(value) {
    return !this.checkEmptyOrNull(value) ?  value : false;
  }

  setZeroasValue(value) {
    return (value || value == 0) ? value : null;
  }

  /**
  * Function returns final object after removing duplicates or unwanted fields
  * @params nonduplicateidset, duplicateIdSetofObjects
  *
  * @author Burela Raju
  * 
  */
  getFinalItemEditedSetBySkippingDuplicates(itemIdsList, itemIdSet) {
    let finalItemList = new Set();
    itemIdsList.forEach(item => {
      let finalobj = {};
      itemIdSet.forEach((duplicateItem: any) => {
        if (item == duplicateItem.id) {
          finalobj = Object.assign(finalobj, duplicateItem.editObj);
        }
      });
      finalItemList.add({ id: item, editObj: finalobj });
    });
    return finalItemList;
  }

  public validateEmailId(emailId) {
    let valid = true
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailId == "" || !regex.test(String(emailId).trim().toLowerCase())) {
      valid = false;
    }
    return valid;
  }

  public checkNumberValidationsPositive(value) {
    let result: boolean = true;
    if (isNaN(value) || parseFloat(value) <= 0) {
      result = false;
    }
    return result;
  } 

  public isNumberPositiveOrEqualToZero(value) {
    let result: boolean = true;
    if (isNaN(value) || parseFloat(value) < 0) {
      result = false;
    }
    return result;
  } 

  isDecimalValue(value) {
    let check: boolean = true;
    value = !this.checkEmptyOrNull(value) ? parseFloat(value) : null
    if (value % 1 != 0) {
      check = false;
    }
    return check;
  }

  getThreeMonthsLaterDate(minDate) {
    return moment(minDate).add(3, 'M');
  }

  public itemSheetDateLimit(dateValue) {
    let result: boolean = true;
    if (dateValue.start && dateValue.start._d && dateValue.end && dateValue.end._d) {
      let months = moment(dateValue.start._d, "MM/DD/YYYY").diff(moment(dateValue.end._d, "MM/DD/YYYY"), 'months');
      if (months <= -(PARAMS.STATIC_PARAMS.ITEM_SHEET_FILTER_MONTHS_LIMIT)) {
        result = false;
      }
    }
    else {
      result = false;
    }
    return result;
  }
}
