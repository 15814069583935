import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { ValidationService } from '@app/services/helpers/validation.service';
import { SidebarService } from '@app/services/sidebar.service';
import { APP_ROUTES } from './routes.constant';
import { environment } from 'environments/environment';

declare var $;
declare var dataLayer: any;
@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit {

  userId: string;
  username: string;
  userEmail: string;
  readonly APP_ROUTES = APP_ROUTES;
  accountRouteList = APP_ROUTES.ACCOUNT_SERVICES;
  finalList = [];
  reportsSubMenu: Array<any> = [];
  othersSubMenus: Array<any> = [];
  arcAccess: boolean = false;
  paasCpoSpoPunching: boolean = false;
  paasAccess: boolean=false;
  constructor(private authService: AuthService,
    public sideBarService: SidebarService,
    private router: Router,
    private validationService: ValidationService
  ) {
    this.authService.moduleLIstChange.subscribe((val) => {
      let accountModules = val;
      this.checkModuleAccess(accountModules);
    });
    this.arcAccess = this.authService.userRoleList.includes('ARC ACCESS') ? true : false;
    this.paasCpoSpoPunching = this.authService.userRoleList.includes('PAAS CPO PUNCHING') ? true : false;
    this.paasAccess=this.authService.userRoleList.includes('PAAS') ? true : false;
    this.initAfterSessionFunctions();
  }
  redirectToUrl() {
    
    dataLayer.push({
      event: "Product_Discovery_Redirect",
      userID: this.userId
    });

    window.open('https://cis.moglix.com/', '_blank');
  }

  initAfterSessionFunctions() {
    if (this.authService.isbuildSessionCompleted() == true) {
      this.updateReportsSubMenu();
      this.updateOtherSubMenus();
    } else {
      setTimeout(() => {
        this.initAfterSessionFunctions();
      }, 1000);
    }
  }

  ngOnInit() {
    let isAuthenticated: boolean = this.authService.isAuthenticated()
    if (isAuthenticated) {
      this.username = this.authService.getUserName();
      this.userEmail = this.authService.getUserEmail();
      this.userId = this.authService.getUserId();
    }
  }

  googleTracking(ga: string) {
    dataLayer.push({ event: "Header_Navigation", action: ga, userID: this.userId });
  }

  checkModuleAccess(moduleList) {
    if (moduleList.length > 0) {
      this.finalList = this.accountRouteList.filter((value) => {
        return moduleList.some((val) => {
          return val.displayName == value.title
        })
      });
    }
  }

  get checkIsManagerOrSuperViser(): boolean {
    return this.authService.getIsManager() || this.authService.checkIsSuperTeamAccess();
  }

  updateReportsSubMenu() {
    let reports: Array<any> = [...APP_ROUTES.REPORTS];

    let enquiryPendencyIndex = reports.findIndex((subMenu: any) => subMenu.title == "Enquiry Pendency");
    if(enquiryPendencyIndex > -1 && !this.checkIsManagerOrSuperViser) reports.splice(enquiryPendencyIndex, 1);

    let arcPerformanceIndex = reports.findIndex((subMenu: any) => subMenu.title == "ARC Performance");  // BusinessDesignationType ->  = 'CITY HEAD' -> 100, 'REGIONAL HEAD' -> 120, 'VP SUPPLY' -> 140
    if(arcPerformanceIndex > -1 && ![100, 120, 140].includes(Number(this.authService.getBusinessDesignationType()))) reports.splice(arcPerformanceIndex, 1);

    this.reportsSubMenu = reports;
  }

  updateOtherSubMenus() {
    const allowedDesignationsIds: number[] = [100, 120, 140]; // 100: 'CITY HEAD', 120: 'REGIONAL HEAD', 140: 'VP SUPPLY'
    const loginedUserRoleList: string[] = this.authService.userRoleList;
    const businessDesignationTypeId: number = Number(this.authService.getBusinessDesignationType());
    const serviceAnalysisRoleName: string = 'SERVICEABILITY ANALYSIS';

    this.othersSubMenus = APP_ROUTES.OTHERS.filter(subMenu => {
      // Only allow the "Serviceability" sub-menu for specific designations or if the user has the serviceability role
      if (subMenu.title === "Serviceability") {
        return allowedDesignationsIds.includes(businessDesignationTypeId) || loginedUserRoleList.includes(serviceAnalysisRoleName);
      }
      return true; // Allow all other sub-menus
    });
  }
}