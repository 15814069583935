import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment as env } from '../../environments/environment';
import { PARAMS } from '../config/params';
import { AlertMessageService } from './alert-message.service';
import * as lodash from 'lodash';
import { ValidationService } from './helpers/validation.service';
import { Observable } from 'rxjs';
import { MESSAGES } from '../config/messages';
import { ErrorValidationsDialogComponent } from '@app/modules/shared/components/error-validations-dialog/error-validations-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { PoService } from './po.service';

@Injectable({
  providedIn: 'root'
})
export class PoItemService {
  constructor(
    private apiService: ApiService,
    private alertMessageService: AlertMessageService,
    private validationService: ValidationService,
    private dialog: MatDialog,
    private poService: PoService
  ) {
    // this.rfqAutomationConf().subscribe((res) => {
    //   this.setConfDataForArcPrice(res.data);
    // });
  }
  teamsListConfArc = [];
  companyListConfArc = [];
  plantListConfArc = [];
  setConfDataForArcPrice(data) {
    this.teamsListConfArc = data?.teamIds;
    this.companyListConfArc = data?.companyIds;
    this.plantListConfArc = data?.plantIds
  }

  public HsnLengthList = [6, 8];
  public hsnMaxInputlength: number = 8;
  public taxValues: any = [];
  public static INDIAN_TAX_TYPE = 'GST';
  public static NON_INDIAN_TAX_TYPE_VAT = 'VAT';
  public static NON_INDIAN_TAX_VALUES = ["0", "5"];
  public static HSN_LENGTH_NON_INDIAN = [6, 7, 8];
  public errorList: any = [];

  public getOmtSheetData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.GET_OMTSHEET_DATA.URL}`;
    return this.apiService.post(url, data);
  }

  public getItemSheetData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.GET_ITEMSHEET_DATA.URL}`;
    return this.apiService.post(url, data);
  }

  public getStatusWiseCount(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.STATUS_WISE_COUNT.URL}`;
    return this.apiService.post(url, data);
  }

  public getSupplierSheetData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.GET_SUPPLIERSHEET_DATA.URL}`;
    return this.apiService.post(url, data);
  }

  public getCatalogSheetData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.GET_CATALOGSHEET_DATA.URL}`;
    return this.apiService.post(url, data);
  }

  public getAuditLogDataForItem(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.GET_AUDIT_LOG_FOR_ITEM.URL}`;
    return this.apiService.post(url, data);
  }

  public getRFQIdSheetData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.GET_RFQ_ITEM_SHEET.URL}`;
    return this.apiService.post(url, data);
  }

  public getRFQDataByrfqId(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.GET_RFQ_DATA_BY_RFQ_ID.URL}`;
    return this.apiService.post(url, data);
  }

  updateRfqItem(data: any) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.UPDATE_RFQ_ITEM_SHEET.URL}`;
    return this.apiService.post(url, data);
  }

  async checkSourcerEmaildata(data) {
    let url = `${env.accountService.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.CHECK_EMAIL_LIST.URL}`;
    return await this.apiService.post(url, data).toPromise();
  }

  public rfqAutomationConf() {
    let url = `${env.mceConfiguration.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.ARC_AUTOMATION_CONF.URL}`;
    return this.apiService.get(url);
  }
  checkConfArcPrice(data) {
    return  this.companyListConfArc?.some(element => element == data.companyId) || this.plantListConfArc?.some(element => element == data.plantId);
  }

  public async checkSourcerEmail(data) {
    let result: boolean = false;
    let displayErrorData: any = '';
    const emails = data;
    let errorEmailData = [];
    let reqData = {
      emailList: data
    }
    let response = await this.checkSourcerEmaildata(reqData);
    if (response.success) {
      if (lodash.has(response, 'data') && !this.validationService.checkEmptyOrNull(response.data)) {
        let responseData = response.data;
        emails.forEach(email => {
          if (!responseData['validSourcerEmails'][email]) {
            errorEmailData.push(email);
            displayErrorData = displayErrorData + `${email}, &nbsp`;
          }
        });
        if (errorEmailData.length > 0) {
          result = false;
          setTimeout(() => {
            this.alertMessageService.addError(displayErrorData + " Email Id`s are Not Valid Sourcer Id").show();
          }, 1000);
        }
        else {
          result = true;
          return result;
        }
      }
      else {
        result = false;
        this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
      }
    } else {
      result = false;
      this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
    }
  }

  public getMsnSupplierDetails(data): Observable<any> {
    let url = `${env.salesOpsSupplier.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SALESOPS.GET_MSN_SUPPLIER_TP.URL}/${data.msn}`;
    return this.apiService.get(url);
  }

  public setTaxValues(data) {
    this.taxValues = data;
  }

  public getTaxValues() {
    return this.taxValues;
  }

  public async checkValidRFQIDs(data, plantId) {
    let punchPoData = this.poService.getPunchPoData();
    let result: boolean = false;
    let rfqItemIds = [];
    data.forEach(obj => {
      if (obj.rfqItemID) {
        rfqItemIds.push(obj.rfqItemID);
      }
    });
    let reqData = {
      rfqIds: rfqItemIds,
      plantId: plantId,
      poReceivedDate: punchPoData?.sapDetails?.moglixReceivedDate || null,
    }
    let response = await this.checkrfqIdsdata(reqData);
    if (response.code == 200 && response.success) {
      if (lodash.has(response, 'data') && !this.validationService.checkEmptyOrNull(response.data)) {
        let responseData = response.data;
        let errors = [];
        if (responseData?.inValidRfqItemId?.length > 0) {
          result = false;
          let invalidRFqItemIdArray = responseData.inValidRfqItemId;
          invalidRFqItemIdArray = invalidRFqItemIdArray.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) == index;
          });
          invalidRFqItemIdArray.forEach(invalidItem => {
            data.forEach(obj => {
              if (obj.rfqItemID && obj.rfqItemID == invalidItem) {
                this.errorList.push(obj);
              }
            });
          });
          errors.push(`${responseData.inValidRfqItemId.toString()}  Are Invalid RFQ Item Ids. <br>`)
        }
        if (responseData?.qtsPendingRfqItemId?.length > 0) {
          result = false;
          errors.push(` ${responseData.qtsPendingRfqItemId.toString()} Are Not System Quoted, Please Quote Through The System To Customer To Do CPO Punching. <br>`);
        }
        if (responseData?.gmPendingRfqItemId?.length > 0) {
          errors.push(`CM Approval Pending for RFQ Item Id/Ids ${responseData.gmPendingRfqItemId.toString()}. <br>`);
        }
        if (responseData?.alreadyPoWonRfqItemId?.length > 0) {
          errors.push(`RFQ Item Id/Ids ${responseData.alreadyPoWonRfqItemId.toString()} are PO won so cannot be re-used again!. <br>`);
        }
        if (responseData?.poReceivedDateLesserThanRfqItemId?.length > 0) {
          errors.push(`Item(s) with ${responseData.poReceivedDateLesserThanRfqItemId.toString()} have PO received date less than RFQ creation date. Please punch this CPO as Direct CPO. <br>`);
        }
        if (errors.length > 0) {
          setTimeout(() => {
            this.alertMessageService.addError(errors.join(' ')).show();
          }, 1000);
          result = false;
        }
        else {
          result = true;
          return result;
        }
      }
      else {
        result = false;
        this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
      }
    } else {
      result = false;
      this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
    }
  }

  async checkrfqIdsdata(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.CHECK_RFQ_IDS.URL}`;
    return await this.apiService.post(url, data).toPromise();
  }

  public getRoutingConfigureData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.GET_ROUTING_CONFIGURATION.URL}`;
    return this.apiService.post(url, data);
  }

  public saveRoutingConfigureData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.SAVE_ROUTING_CONFIGURATION.URL}`;
    return this.apiService.post(url, data);
  }

  async validateCPNForMultipleLineItems(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.CHECK_ARC_CPN_FOR_PO_ITEM.URL}`;
    return await this.apiService.post(url, data).toPromise();
  }

  public async checkARCConfiguration(data, sourcer?) {
    let result: boolean = false;
    let displayErrorData: any = '';
    let errorData = [];
    let invalidPriceDiff = "";
    let reqData = data;
    let response = await this.validateCPNForMultipleLineItems(reqData);
    if (response.code == 200 && response.success) {
      if (lodash.has(response, 'data') && !this.validationService.checkEmptyOrNull(response.data)) {
        let responseData = response.data;
        if (responseData['cpnValidation']['inValidCpn'] && responseData['cpnValidation']['inValidCpn'].length > 0) {
          let invalidCpnArray = responseData['cpnValidation']['inValidCpn'];
          invalidCpnArray = invalidCpnArray.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) == index;
          });
          invalidCpnArray.forEach(invalidcpn => {
            data.forEach(obj => {
              if (obj.cpn && obj.cpn == invalidcpn) {
                errorData.push(obj);
                displayErrorData = displayErrorData + `${invalidcpn}, &nbsp`;
              }
            });
          });
        }
        else if (responseData['cpnValidation']['priceDiffResponseList'] && responseData['cpnValidation']['priceDiffResponseList'].length > 0) {
          responseData['cpnValidation']['priceDiffResponseList'].forEach((item: any) => {
            if(!item.punchingAllowed) {
              invalidPriceDiff += `${item.cpn}(price: ${item.arcPrice}); `;
            }
          })
        }
        else if((responseData['cpnValidation']['invalidSpLesser'] && responseData['cpnValidation']['invalidSpLesser'].length > 0) || responseData['cpnValidation']['invalidSpGreater'] && responseData['cpnValidation']['invalidSpGreater'].length > 0){
          let warning = '';
          if(responseData['cpnValidation']['invalidSpLesser'] && responseData['cpnValidation']['invalidSpLesser'].length > 0) {
            let invalidSpLesserArray = responseData['cpnValidation']['invalidSpLesser'];
            invalidSpLesserArray.forEach((invalidSp) => {
              if (!this.validationService.checkEmptyOrNull(invalidSp.cpn) && !this.validationService.checkEmptyOrNull(invalidSp.sp) && !this.validationService.checkEmptyOrNull(invalidSp.arcPrice)) {
                warning = warning + `CPN   ${invalidSp.cpn} received is at lower SP ${invalidSp.sp} than ARC price ${invalidSp.arcPrice}. <br>`;
              }
            });
          }
          if(responseData['cpnValidation']['invalidSpGreater'] && responseData['cpnValidation']['invalidSpGreater'].length > 0) {
            let invalidSpGreaterArray = responseData['cpnValidation']['invalidSpGreater'];
            invalidSpGreaterArray.forEach((invalidSp) => {
              if (!this.validationService.checkEmptyOrNull(invalidSp.cpn) && !this.validationService.checkEmptyOrNull(invalidSp.sp) && !this.validationService.checkEmptyOrNull(invalidSp.arcPrice)) {
                warning = warning + `CPN   ${invalidSp.cpn} received is at greater SP ${invalidSp.sp} than ARC price ${invalidSp.arcPrice}. <br>`;
              }
            });
          }
          if (warning.length > 0) {
            // if (this.checkConfArcPrice(data[0])) {
              warning = warning + 'Reach out to customer and get CPO amended!'
              setTimeout(() => {
                this.alertMessageService.addWarning(warning).show();
              }, 0)
            // }
          }
        }
        if (errorData.length > 0) {
          // errorData.forEach(errorCpn => {
          //   this.errorList.push(errorCpn);
          // });
           for await ( let errorCpn of errorData){
            this.errorList.push(errorCpn);
           }
          setTimeout(() => {
            this.alertMessageService.addError(displayErrorData + MESSAGES.ERROR.PO_ERROR.ERROR_ARCCONFIGCHECK_CPN_VALIDATION).show();
          }, 1000);
        }
        else if (invalidPriceDiff.length > 0 && sourcer!='SAP') {
          setTimeout(() => {
            this.alertMessageService.addError("ARC CPN - " + invalidPriceDiff + "&nbsp" + MESSAGES.ERROR.PO_ERROR.ERROR_PRICE_DIFF_CPN_VALIDATION).show();
          }, 1000);
        }
        else {
          result = true;
        }
      }
      else {
        this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
      }
    } else {
      this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
    }
    return result;
  }
  public async checkARCConfigurationForCNCI(data, sourcer) {
    let result: string = 'false';
    let displayErrorData: any = '';
    let errorData = [];
    let invalidPriceDiff = "";
    let reqData = data;
    let response = await this.validateCPNForMultipleLineItems(reqData);
    if (response.code == 200 && response.success) {
      if (lodash.has(response, 'data') && !this.validationService.checkEmptyOrNull(response.data)) {
        let responseData = response.data;
        if (responseData['cpnValidation']['inValidCpn'] && responseData['cpnValidation']['inValidCpn'].length > 0) {
          let invalidCpnArray = responseData['cpnValidation']['inValidCpn'];
          invalidCpnArray = invalidCpnArray.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) == index;
          });
          invalidCpnArray.forEach(invalidcpn => {
            data.forEach(obj => {
              if (obj.cpn && obj.cpn == invalidcpn) {
                errorData.push(obj);
                displayErrorData = displayErrorData + `${invalidcpn}, &nbsp`;
              }
            });
          });
        }
        else if (responseData['cpnValidation']['priceDiffResponseList'] && responseData['cpnValidation']['priceDiffResponseList'].length > 0) {
          responseData['cpnValidation']['priceDiffResponseList'].forEach((item: any) => {
            if(!item.punchingAllowed) {
              invalidPriceDiff += `${item.cpn}(price: ${item.arcPrice}); `;
            }
          })
        }
        else if((responseData['cpnValidation']['invalidSpLesser'] && responseData['cpnValidation']['invalidSpLesser'].length > 0) || responseData['cpnValidation']['invalidSpGreater'] && responseData['cpnValidation']['invalidSpGreater'].length > 0){
          let warning = '';
          if(responseData['cpnValidation']['invalidSpLesser'] && responseData['cpnValidation']['invalidSpLesser'].length > 0) {
            let invalidSpLesserArray = responseData['cpnValidation']['invalidSpLesser'];
            invalidSpLesserArray.forEach((invalidSp) => {
              if (!this.validationService.checkEmptyOrNull(invalidSp.cpn) && !this.validationService.checkEmptyOrNull(invalidSp.sp) && !this.validationService.checkEmptyOrNull(invalidSp.arcPrice)) {
                warning = warning + `CPN   ${invalidSp.cpn} received is at lower SP ${invalidSp.sp} than ARC price ${invalidSp.arcPrice}. <br>`;
              }
            });
          }
          if(responseData['cpnValidation']['invalidSpGreater'] && responseData['cpnValidation']['invalidSpGreater'].length > 0) {
            let invalidSpGreaterArray = responseData['cpnValidation']['invalidSpGreater'];
            invalidSpGreaterArray.forEach((invalidSp) => {
              if (!this.validationService.checkEmptyOrNull(invalidSp.cpn) && !this.validationService.checkEmptyOrNull(invalidSp.sp) && !this.validationService.checkEmptyOrNull(invalidSp.arcPrice)) {
                warning = warning + `CPN   ${invalidSp.cpn} received is at greater SP ${invalidSp.sp} than ARC price ${invalidSp.arcPrice}. <br>`;
              }
            });
          }
          if (warning.length > 0) {
            // if (this.checkConfArcPrice(data[0])) {
              warning = warning + 'Reach out to customer and get CPO amended!'
              setTimeout(() => {
                this.alertMessageService.addWarning(warning).show();
              }, 1000)
              return 'warning';
            // }
          }
        }
        if (errorData.length > 0) {
          // errorData.forEach(errorCpn => {
          //   this.errorList.push(errorCpn);
          // });
           for await ( let errorCpn of errorData){
            this.errorList.push(errorCpn);
           }
          setTimeout(() => {
            this.alertMessageService.addError(displayErrorData + MESSAGES.ERROR.PO_ERROR.ERROR_ARCCONFIGCHECK_CPN_VALIDATION).show();
          }, 0);
        }
        else if (invalidPriceDiff.length > 0 && sourcer!='SAP') {
          setTimeout(() => {
            this.alertMessageService.addError("ARC CPN - " + invalidPriceDiff + "&nbsp" + MESSAGES.ERROR.PO_ERROR.ERROR_PRICE_DIFF_CPN_VALIDATION).show();
          }, 1000);
        }
        else {
          result = 'true';
        }
      }
      else {
        this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
      }
    } else {
      this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
    }
    return result;
  }


  public getAvailableInventoryDetails(data): Observable<any> {
    let url = `${env.wms.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.WMS.GET_AVAILABLE_INVENTORY.URL}`;
    return this.apiService.post(url, data);
  }

  public poItemValidationErrors() {
    const dialogRef = this.dialog.open(ErrorValidationsDialogComponent, {
      width: '90%',
      height: '90%',
      data: { errorValidationData: this.errorList }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public resetErrorList() {
    this.errorList = [];
  }

  public getMsnwiseSpDetails(data): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.Fetch_SP_HISTORY_MSNWISE.URL}`;
    return this.apiService.post(url,data);
  }

  public getItemDetailsUsingItemIds(data): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.GET_ITEMS_DETAILS_USING_ITEMIDS.URL}`;
    return this.apiService.post(url,data);
  }

  public closePoItems(data):  Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.CLOSE_PO_ITEMS_USING_ITEMIDS.URL}`;
    return this.apiService.post(url,data);
  }

  public bulkUpdateUpload(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.ITEM_SHEET_BULK_UPDATE_UPLOAD.URL}`;
    return this.apiService.post(url, data);
  }

  submitSupplierSelectionReason(data: any): Observable<any> {
    let url: string = ' '
    return this.apiService.post(url, data);
  }
}