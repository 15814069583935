export const SHEET_DATA_TYPE = {
    ITEM_SHEET_FIELDS_TYPE: {
        "product.extraDetails.clientData.annexure": "string",
        "catalog.remarkFromCatalog": "string",
        "catalog.remarkFromBusiness": "string",
        "catalog.msnType": "string",
        "catalog.catalogStatus": "string",
        "sourcing.pickupWarehouse": "string",
        "sourcing.pickupWarehouseId": "string",
        "sourcing.diskReceived": "string",
        "delivery.pricingMechanism": "string",
        "delivery.qtyToBeInvoiced": "number",
        "delivery.cddBreachReason": "string",
        "delivery.cdd2Reason": "string",
        "delivery.cdd2": "date",
        "ems.poDataRemark1": "string",
        "sourcing.supplierFollowDate": "date",
        "catalog.clarificationClosed": "string",
        "catalog.assignee": "string",
        "catalog.priority": "string",
        "catalog.catalogPoc": "catalogPoc",
        "sourcing.listPrice": "number",
        "remarks.remark1": "string",
        "remarks.remark2": "string",
        "remarks.remark3": "string",
        "remarks.remark4": "string",
        "remarks.remark5": "string",
        "remarks.remark6": "string",
        "remarks.remark7": "string",
        "remarks.remark8": "string",
        "remarks.remark9": "string",
        "remarks.remark10": "string",
        "sourcing.suggestedBrand": "string",
        "sourcing.omtStatus": "string",
        "delivery.opsAllocation": "string",
        "tolerence": "number",
        "remark": "string",
        "sourcing.purchaserName": "string",
        "warehouse": "string",
        "catalog.urgencyClassification": "string",
        "sourcing.customerCreditDays": "string",
        "delivery.dropship": "string",
        "sourcing.supplierPickupDueDate": "date",
        "sourcing.omtPoc": "string",
        "sourcing.routingConfiguration": "string",
        "sourcing.autoSupplierCancellationRemarks": "string",
        "sourcing": "string",
        "delivery.customerFreightTerms": "string",
        "poStatus": "string",
        "delivery.torApplicability": "string",
        "sourcing.poDocumentDate": "date",
        "emsItemId": "number",
        "extraDetails.tolerance": "number",
        "sourcing.suggestSupplier": "string",
        "sourcing.suggestSupplierName": "string",
        "sourcing.suggestSupplierId": "string",
        "sourcing.suggestedSupplierPaymentTerms": "string",
        "sourcing.suggestedSupplierPaymentTermsId": "string",
        "deliveryType" : "string",
        "ems.poStatus": "string",
        "customerFollowupDate": "date",
        "freightCharges": "number",
        "loadingCharges": "number",
        "miscCharges": "number",
        "finalTpUnit": "number",
        "sourcing.defaulterSupplier": "boolean",
        "manualCustomerEta": "date"
    },
    CATALOG_SHEET_FIELDS_TYPE: {
        "product.extraDetails.clientData.annexure": "string",
        "catalog.remarkFromCatalog": "string",
        "catalog.remarkFromBusiness": "string",
        "catalog.msnType": "string",
        "catalog.catalogStatus": "string",
        "catalog.clarificationClosed": "string",
        "catalog.assignee": "string",
        "catalog.priority": "string",
        "catalog.catalogPoc": "catalogPoc",
        "sourcing.suggestedBrand": "string",
        "catalog.urgencyClassification": "string",
        "sourcing.supplierPickupDueDate": "date",
        "catalog.needToWork": "string",
        "catalog.timeOfRequest": "date",
        "catalog.additionalRemark1": "string",
        "catalog.additionalRemark2": "string"
    },
    OMT_SHEET_FIELDS_TYPE: {
        'sourcing.sourcingRemarkToOmt': "string",
        'sourcing.omtStatus': "string",
        'sourcing.omtPoc': "string",
        'sourcing.supplierFollowDate': "date",
        'sourcing.supplierPickupDueDate': "date",
        'sourcing.supplierPickupDate': "date",
        'sourcerEmail': "string",
        'delivery.eta': "date",
        'delivery.customerDueDeliveryDate': "date",
        'remarks.remark9': "string",
        'remarks.remark3': "string",
        'remarks.remark4': "string",
        'delivery.cdd2': "date",
        'delivery.cdd2Reason': "string",
        'delivery.cddBreachReason': "string",
        'eta.preShippingTime': "number",
        'eta.bufferTime': "number",
        "manualCustomerEta": "date"
    },
    SUPPLIER_SHEET_FIELDS_TYPE: {
        'quantity.qty': "number",
        'quantity.uom': "string",
        'ems.poStatus': "string",
        'product.extraDetails.clientData.annexure': "string",
        'sourcing.sourcingRemarkToOmt': "string",
        'sourcing.omtStatus': "string",
        'sourcing.supplierFollowDate': "date",
        'deliveryType':'string',
        'sourcing.listPrice': "number",
        'sourcing.diskReceived': "number",
        'sourcing.supplierCreditDays': "string",
        'sourcing.supplierPickupDueDate': "date",
        'sourcing.suggestSupplier': "string",
        'sourcing.suggestSupplierId': "string",
        'sourcing.suggestSupplierName': "string",
        'sourcing.suggestedSupplierPaymentTerms': "string",
        'sourcing.suggestedSupplierPaymentTermsId': "string",
        'sourcing.pickupWarehouse': "string",
        'sourcing.supplierPickupDate': "date",
        'sourcing.routingConfiguration': "string",
        'sourcerEmail': "string",
        'delivery.dropship': "string",
        'remarks.remark9': "string",
        'sourcing.discount': "number"
    }
}